@import "main/variables_placowka_edukacyjna";
@import "main/importer";

@import "main/bootstrap";

.default_font {
    @import "main/default_font";
}

.default_form {
    @import "main/default_form";
}

@import "../js/plugins/select2/select2";
@import "../js/plugins/jquery_mCustomScrollbar/jquery.mCustomScrollbar.scss";

@import "main/style";

.btn {
    &:hover {
        text-decoration: underline;
        filter: none !important;
        //background-color: $opacity-main !important;
        border-color-color: $opacity-main !important;
    }
}

.margin_bottom {
    margin-bottom: 32px;
}


div.corner-left-bottom rect, div.corner-top-right rect {
    fill: $theme-main;
}

div.heading rect, div.category rect {
    fill: $theme-main;
}

.addon_element [fill="#4e7d1e"] {
    fill: $theme-icon;
}

.calendar_heading {
    rect {
        fill: $theme-main !important;
    }
}

.sidebar-wrapper, .schools_block, .location, .mail, .phone {
    path {
        fill: $theme-main !important;
    }

    rect {
        fill: transparent;
    }
}

.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            .calendar-wrapper {
                background-color: $opacity-main;
            }
        }
    }
}

.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            .calendar-wrapper {
                background-color: $opacity-main;

                .mycalendar2_datepicker {
                    .base_bg_color {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            .shortcuts-wrapper {
                .item-list {
                    .item {
                        border: none;
                        border-radius: $border-radius-2;
                        background-color: $opacity-main;
                    }

                    &:not(.aside_desc_box) {
                        .item {
                            overflow: hidden;
                            display: flex;

                            &:before {
                                position: absolute;
                                content: '';
                                width: 6px;
                                top: 16px;
                                left: -3px;
                                border-radius: 3px;
                                height: calc(100% - 32px);
                                background-color: $theme-main;;
                                z-index: 3;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dark_block {
    background-color: $theme-cta;
}

.dark_block {
    .checkbox-wrapper {
        color: $color_white;

        .field_checkbox {
            .field_checkbox_box {
                .checkbox_image_box {
                    > div {
                        border-color: $color_white;
                    }
                }
            }
        }
    }
}


footer {
    background-color: transparent;

    .contacts {
        svg {
            path {
                fill: $color-white !important;
            }
        }
    }

    > .container {
        ul {
            a {
                color: $color-gray-900 !important;
            }
        }
    }

    > .content-wrapper {
        background-color: $theme-cta;

        ul {
            a {
                color: $color-white !important;
            }
        }
    }

    .location {
        span {
            color: $color-white !important;
        }
    }

    .menu_list-wrapper {
        .menu_list {
            a {
                color: $color-white !important;
            }
        }
    }

    .content-wrapper {
        .parts-list {
            .part {
                .annotation {
                    color: $color-white !important;
                }

                .heading {
                    color: $color_white !important;
                }

                .contacts {
                    a {
                        color: $color-white !important;
                    }
                }

            }
        }

        .parts-list {
            .part {
                .heading {
                    &:after {
                        background-color: $theme-main !important;
                    }
                }
            }
        }
    }

    .footer_bottom {
        color: $color-gray-900 !important;
        //border-color: $color-gray-200 !important;
        border: none !important;
    }
}

.main_banner-wrapper {
    .carousel_elements_parent-wrapper {
        .main_banner {
            .content_block-wrapper {
                //background-color: $theme-main;
                background-color: $theme-cta;
            }
        }
    }
}

.newsletter_block-wrapper {
    .newsletter_block {
        form {
            .form_group {
                input[type="submit"] {
                    color: $theme-cta-font;

                    &:hover {
                        filter: none !important;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.multimedia_block-wrapper {
    .multimedia_block {
        .item-list {
            .item {
                overflow: hidden;
                border-radius: $border-radius-2;
                background-color: $color_white;

                .preview {
                    border-radius: 0;
                }

                .content {
                    padding: 0 20px 20px 20px;
                }
            }
        }
    }
}

.sidebar-wrapper, .schools_block, .location, .mail, .phone {
    path {
        fill: $theme-icon !important;
    }

    rect {
        fill: transparent;
    }
}

.mycalendar2_datepicker .calendar_content header.months_change .left {
    background-color: $color_white;
    text-align: center !important;
    border-radius: $border-radius-4;
    vertical-align: middle !important
}

.mycalendar2_datepicker .calendar_content header.months_change .right {
    background-color: $color_white;
    text-align: center !important;
    border-radius: $border-radius-4;
    vertical-align: middle !important
}

//.mycalendar2_datepicker .calendar_content .days_table .td.active, .mycalendar2_datepicker .calendar_content .days_table .td.active_selected{
//    background-color: $color_white !important;
//}

.mycalendar2_datepicker .calendar_content header.months_change .left svg {
    height: 16px !important;
}

.shortcuts_block {
    rect {
        fill: $theme-main;
    }
}

.mycalendar2_datepicker .calendar_content header.months_change .right svg {
    height: 16px !important;
}

.mycalendar2_datepicker .calendar_content header.months_change .title {
    vertical-align: middle;
}

header .bottom_header-wrapper {
    background-color: $theme-cta;

    .header_menu {
        .menu_dots {
            color: $color_white !important;

            p {
                line-height: 64px;
                display: block;
            }
        }

        > ul {
            > li {
                padding: 0 23px;
                margin: 0 !important;
                border-right: 1px solid rgba(255, 255, 255, 0.15);

                &:first-child {
                    border-left: 1px solid rgba(255, 255, 255, 0.15);
                }
            }
        }
    }
}

header .bottom_header-wrapper .bottom_header .header_menu > ul > li > .link_content-wrapper a {
    padding: 0;
    color: $color_white;
    line-height: 64px;

    &:hover {
        color: $color_white;
        text-decoration: underline;
    }
}

.header_menu {
    > ul {
        > li {
            > div {
                > .has_more_btn {
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url("./../svg/expand2.svg") !important;
                    > svg{
                        display: none !important;
                    }
                    img {
                        display: none;
                    }
                }
            }
        }
    }
}

.downloads {
    .list {
        .downloads_item {
            background-color: $color-gray-100 !important;
            width: 100% !important;
            display: flex !important;
            flex-flow: row;
            flex-wrap: nowrap;
            border-color: $color-gray-200 !important;
            span{
                color: $color-gray-900 !important;
            }
            .addon_element {
                path {
                    fill: $theme-icon;
                }
            }

            svg {
                //margin-left: auto;
                margin-left: 0;
                order: 3;
            }

            .file_name {
                order: 1;
                color: $color-gray-900 !important;
            }

            .additions {
                order: 2;

                span {
                    background-color: transparent !important;
                    color: $color-gray-900 !important;
                    padding: 0 !important;
                    font: normal normal normal 14px/19px Lato !important;
                }
            }
        }

        .downloads_item:hover {
            transform: scale(1.03);
        }

        width: 100% !important;
    }
}


.article_page-wrapper {
    .article_block-wrapper {
        .article_block {
            .block_content-wrapper {
                .faq {
                    .list {
                        .faq_item {
                            border-radius: $border-radius-2 !important;
                            background-color: $color-gray-100;
                            overflow: hidden;

                            .downloads_item {
                                background-color: $color-gray-100 !important;
                                width: 100% !important;
                                display: flex !important;
                                flex-flow: row;
                                flex-wrap: nowrap;
                                border-color: $color-gray-200 !important;

                                .addon_element {
                                    path {
                                        fill: $theme-icon;
                                    }
                                }

                                svg, img {
                                    margin-left: auto;
                                    order: 3;
                                }

                                .file_name {
                                    order: 1;
                                    color: #1861C6 !important;
                                }

                                .additions {
                                    order: 2;

                                    span {
                                        background-color: transparent !important;
                                        color: #1861C6 !important;
                                        padding: 0 !important;
                                        font: normal normal normal 14px/19px Lato !important;
                                    }
                                }
                            }

                            .downloads_item {
                                padding: 16px;
                            }

                            &.active {
                                background-color: $color_white;

                                &:hover {
                                    .question {
                                        background-color: $color_white;
                                    }
                                }
                            }

                            .question {
                                justify-content: space-between;
                                width: 100%;
                                color: #151B28;
                                padding: 12px 16px;
                                font: normal normal bold 14.4px/24.4px Lato;
                                cursor: pointer;

                                span {
                                    margin: 0;
                                    font: normal normal bold 14.4px/24.4px Lato;
                                }
                            }

                            .answer {
                                position: relative;
                                padding-top: 14px;
                                //font: normal normal bold 14.4px/24.4px Lato;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 16px;
                                    width: calc(100% - (16px * 2));
                                    border-top: 2px solid $color-gray-200;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    header {
        .bottom_header-wrapper {
            .bottom_header {
                .header_menu {
                    .has_more_btn {
                        background-image: url("./../svg/expand2.svg") !important;
                    }

                    > ul {
                        > li {
                            border-right: 0 !important;

                            > ul {
                                li {
                                    background-color: transparent !important;
                                }

                                background-color: transparent !important;

                                a {
                                    color: $color_white !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mycalendar2_datepicker .calendar_content .days_table .td.active:after, .mycalendar2_datepicker .calendar_content .days_table .td.active_selected:after {
    display: none;
}

.siblings_events_block-wrapper .events_list-wrapper .item-list .item {
    border-radius: 0 !important;
    .date{
        border-radius: 0 !important;
    }
}


.placowka_edukacyjna {
    // Efekt przybliżenia zdjęcia
    .events_list-wrapper, .shortcuts-wrapper, .institutions_list-wrapper, .gallery_item, .multimedia_block-wrapper, .gallery_list {
        .item {
            transition: 0.3s transform ease;

            &:hover {
                .preview {
                    transform: scale(0.95);
                }
            }
        }

        .preview {
            transition: 0.3s transform ease; /* Dodaj przejście dla .preview na poziomie wyższego selektora */
        }
    }

    // Efekt obramowania
    .schools_block {
        .item-list {
            a.item {
                //display: inline-block;
                position: relative;
                overflow: hidden; /* Ukryj część obramowania, która może wystawać */
                transition: 0.7s transform, 0.7s border, 0.7s border-radius;

                &:hover {
                    background-color: var(--opacity-main);
                }
            }
        }
    }
    .newest_info_block-wrapper {
        .item-list {
            a.item {
                display: inline-block;
                transition: 0.3s all ease;

                &:hover {
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
    .sidebar {
        .item-list {
            a {
                .item {
                    display: inline-block;
                    position: relative;
                    //overflow: hidden; /* Ukryj część obramowania, która może wystawać */
                    overflow: visible !important;
                    &:hover {
                        opacity: 0.8;
                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            //top: 50%;
                            //left: 50%;
                            //transform: translate(-50%, -50%);
                            width: 100%;
                            height: 100%;
                            border: solid 1px var(--theme-main) !important;
                            border-radius: 5px;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }

}

.gallery_list{
    .item{
        .preview{
            height: 173px;
            img{
                height: 100% !important;
            }
        }
        .content{
            height: calc(100% - 173px);
            display: block !important;
            .heading{
                margin-bottom: 10px;
            }
        }
    }
}

.mycalendar2_datepicker .calendar_content .days_table .td.active, .mycalendar2_datepicker .calendar_content .days_table .td.active_selected{
    background-color: transparent;
    border-color: transparent !important;
}

.categories_tab{

    .categories_tabs_list & {
        color: #4A5469 !important;
        text-decoration: none;
        &.active, &:hover{
            color: $theme-cta-font !important;
        }
        &.hover{
            text-decoration: underline !important;
        }
    }
}

.header_menu{
    .has_more_btn{
        line-height: 0 !important;
    }
    svg{
        path{
            @media only screen and (max-width: 767px) {
                fill: #ffffff !important;
            }
        }
    }
    ul{
        li {
            ul {
                path{
                    //fill: #000000 !important;
                }
            }
        }
    }
}
.article_block-wrapper{
    .tag_list{
        > div{
            &:first-child{
                rect{
                    fill: $theme-main;
                }
            }
        }
    }
}

.gray_block{
    margin-bottom: 0 !important;
}

.myajaxlist_paginate_box{
    .one_page{
        &.active {
            background-color: transparent !important;
            border: 1px solid $theme-cta !important;
            color: #000000 !important;
        }
    }
}

.menu_holder_box1{
    > ul{
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;
        @media only screen and (max-width: 991px) {
            display: block !important;
        }
        > li{
            margin-right: 0 !important;
        }
    }
}
